export interface RaduoType {
    label: string
    score: string
    checked: boolean
    uuid: string
}
export class RaduoInit {
    raduoList: RaduoType[] = [
        {
            label: '随机5题',
            score: '20分/题',
            uuid: 'e8c75ab5-b2b0-484d',
            checked: true
        },
        {
            label: '随机10题',
            score: '10分/题',
            uuid: '0c2a0033-01bf-4b2a',
            checked: false
        },
        {
            label: '随机20题',
            score: '5分/题',
            uuid: '28f42a3c-2153-4b38',
            checked: false
        }
    ]
}

export class Raduo2 {
    raduo2List: RaduoType[] = [
        {
            label: '抽取5题',
            score: '20分/题',
            uuid: 'e8c75ab5-b2b0-484d',
            checked: true
        },
        {
            label: '抽取10题',
            score: '10分/题',
            uuid: '0c2a0033-01bf-4b2a',
            checked: false
        },
        {
            label: '抽取20题',
            score: '5分/题',
            uuid: '28f42a3c-2153-4b38',
            checked: false
        }
    ]
}
